import React, {useState, useEffect, useRef} from 'react';
import EnrollmentPrintAndExport from './EnrollmentPrintAndExport';
import EnrollmentEmail from './EnrollmentEmail';
import {EnrollmentExtraHeaders, EnrollmentProjection, initialHeaders} from "./EnrollmentProjection";

/**
 * Component for displaying the Table of enrollments.
 */
export default function EnrollmentTable({i18n, context, filters, participations,
                                            headers:allHeaders, headerState, paperHeaderEnabledState, extraHeadersState, extraHeadersEnabledState, userEmail, search}) {
    const {course: {name: title='', course_code: label='', id }, sections, programs, groups} = context;
    const {enrollments, groupMembers, memberGroups} = participations;

    const [extraHeadersEnabled, setExtraHeadersEnabled] = extraHeadersEnabledState;
    const [extraHeaders, setExtraHeaders] = extraHeadersState;

    const tableName = `${i18n('main.header.preamble')}${i18n('main.header.preposition')} ${label} ${title}`;

    const [headers2, setHeaders] = headerState;
    const headers = [...allHeaders.filter(h => h.enabled), ...extraHeaders];
    const headerLabels = headers.map(h => h.label);
    const [sort, setSort] = useState(headers.length>0 ? Object.assign({}, headers[1], {asc: true}) : {});

    const [allSelected, setAllSelected] = useState(false);
    const [isChecked, setIsChecked] = useState([]);

    const rows = mergeUserEnrollments(filterEnrollments(enrollments, filters, groupMembers, search))
        .sort(enrollmentComparator(i18n, sort, programs, sections, filters.groups, groups, memberGroups))
        .map(e => headers.map(h => enrollmentValue(i18n, h, e, programs, sections, filters.groups, groups, memberGroups)));

    return (
        <>
            <div className="row no-print">
                <div className="col-md-12 text-left">
                    <h3 className="toggle-header">
                        {/*  <button className="button-outline button-icon icon-add me-1" onClick={selectExtraHeaders}>{i18n('projection.extra.headers')}</button>*/}
                        <button className="toggle-button button-text button-icon m-0" data-bs-toggle="collapse"
                                data-bs-target="#toggle-headers" aria-controls="toggle-headers" aria-expanded="false">{i18n('projection.show')}</button>
                    </h3>
                </div>
            </div>

            <div className="row no-print mb-4 toggle-content collapse" id="toggle-headers">
                <div className="col-md-12 text-left">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <EnrollmentProjection i18n={i18n} headerState={[headers2, setHeaders]}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row no-print mb-3 mt-5">
                <div className="col-md-12 text-left">
                    <EnrollmentExtraHeaders i18n={i18n} context={context} filters={filters}
                                            extraHeadersEnabledState={[extraHeadersEnabled, setExtraHeadersEnabled]}
                                            extraHeadersState={[extraHeaders, setExtraHeaders]} />
                    <EnrollmentPrintAndExport i18n={i18n} tableName={tableName} headers={headerLabels} rows={rows} />
                    <EnrollmentEmail i18n={i18n} context={context} rows={rows} isChecked={isChecked} userEmail={userEmail} headers={headers}/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <span>{i18n('number.of.students')} {rows.length}</span>
                    <table className="table table-striped table-sort" id="enrollmentTable">
                        <caption>{tableName}</caption>
                        <thead>
                            <tr>{headers.map((h, index) => h.prop === 'user_checkbox' ?
                                <ThSelectAll i18n={i18n} key={index + 123}  h={h} rows={rows} setIsChecked={setIsChecked} allSelected={allSelected} setAllSelected={setAllSelected}/> :
                                <ThSortable key={index} h={h} sortState={[sort, setSort]}/>)
                            }
                                {extraHeaders.map((h, j) =>
                                    <ExtraHeader i={j} h={h} extraHeaders={extraHeaders} setExtraHeaders={setExtraHeaders}/>)
                                }
                            </tr>
                        </thead>
                        <tbody>{rows.map((r, index1) =>
                            <tr key={index1}>{r.map((c, index2) => (c||{}).type === 'select' ?
                                <TdSelect i18n={i18n} key={index2} user_id={c.enrollment.user_id} email={c.enrollment.user_email} isChecked={isChecked} setIsChecked={setIsChecked}/>  :
                                <TdMultiLine key={index1 + index2} d={c} />)}
                            </tr>)}
                        </tbody>
                    </table>
                </div>
            </div>

            <EnrollmentPrintAndExport i18n={i18n} tableName={tableName} headers={headerLabels} rows={rows} />
            <EnrollmentEmail i18n={i18n} context={context} rows={rows} isChecked={isChecked} userEmail={userEmail} headers={headers}/>
        </>
    );
}

function TdSelect({i18n, i, user_id, email, isChecked, setIsChecked}) {
    const onChange = e => {
        const { id, checked } = e.target;
        setIsChecked([...isChecked, id]);
        if (! checked) {
            setIsChecked(isChecked.filter(item => item !== id));
        }
    };
    return <td key={i} className="emailColumn no-print" >
        <input type='checkbox' title={i18n('table.select_checkbox') + ' ' + email} aria-checked={isChecked.includes(user_id)} id={user_id} onChange={onChange} checked={isChecked.includes(user_id)} />
    </td>;
}

function ThSelectAll({i18n, i, rows, setIsChecked, allSelected, setAllSelected}) {
    const selectAll = e => {
        setAllSelected(! allSelected);
        if (allSelected) {
            setIsChecked([]);
        } else {
            setIsChecked(rows.map(row => row[0].enrollment.user_id));
        }
    };
    return <th key={i} className="emailColumn no-print">
        <input type='checkbox' title={i18n('table.header_title.select_all')} aria-checked={allSelected} className="emailCheckbox no-print" onChange={selectAll} checked={allSelected}/>
    </th>
}

function ThSortable({i, h, sortState:[sort, setSort]}) {
    if (h.type === 'extra') {
        return null;
    }
    const clickHeader = h => e => {
        e.preventDefault();
        let headers = document.querySelectorAll("#enrollmentTable > thead > tr > th > a");
        for (let j = 0; j < headers.length; j++) {
            headers[j].className = 'no-print icon-position-right icon-sort';
        }
        setSort(Object.assign({}, h, {asc: (sort.prop === h.prop ? !sort.asc : true)}));
        let sortClass = sort.asc ? 'up' : 'down';
        e.target.className = 'no-print icon-position-right icon-sort-' + sortClass;
    };
    return <th key={i}><a href="#" onClick={clickHeader(h)} className="no-print icon-position-right icon-sort">{h.label}</a>
    <span className="printout-header">{h.label}</span>
    </th>;
}

function ExtraHeader({i, h, extraHeaders, setExtraHeaders}) {
    const [isEditMode, setEditMode] = useState(false);
    const inputRef = useRef(null);
    React.useEffect(() => {
        if (isEditMode) {
            setTimeout(() => {
                inputRef.current.focus();
                inputRef.current.select();
            }, 300);
        }
    }, [isEditMode]);
    let label = h.label;
    const edit = h => e => {
        setEditMode(current => ! current);
    };
    const done = e => {
        h.label = inputRef.current.value;
        setEditMode(current => ! current);
    };
    const removeHeader = h => e => {e.preventDefault(); setExtraHeaders(extraHeaders.filter(eh => eh.prop !== h.prop))}
    const handleKeyDown = e => {
        if (e.key === "Enter") {
            done();
        }
    }
    return <th key={'extra-' + i}>
        <div className="no-print edit-extra-header">
            <span className={isEditMode ? "edit-column" : "edit-column-visible"}>{label}</span>
            <span onClick={edit(h)} className={isEditMode ? "no-print icon-position-right icon-edit edit-column" : "no-print icon-position-right icon-edit edit-column-visible edit-column-hand"}>
            </span>
            <span onClick={removeHeader(h)} className={isEditMode ? "no-print icon-position-left icon-delete edit-column" : "no-print icon-position-left icon-delete edit-column-visible edit-column-hand"}>
            </span>
            <input onKeyDown={handleKeyDown} autoFocus={true} autoComplete="off" ref={inputRef} className={isEditMode ? "icon-position-right icon-edit edit-column-visible" : "icon-position-right icon-edit edit-column"} type="text" defaultValue={label} id={"extra-column-" + i}/>
            &nbsp;&nbsp;
            <span onClick={done} className={isEditMode ? "no-print icon-position-left icon-done edit-column-visible edit-column-hand" : "icon-position-left icon-done edit-column"}>
            </span>
        </div>
        <span className="printout-header">{label}</span>
    </th>;
}

function TdMultiLine({i, d}) {
    return <td key={i + 15}>{Array.isArray(d) ? (d||[]).map(l => <div key={i + 1}>{l}</div>) : d}</td>;
}

function filterEnrollments(enrollments, filters, groupMembers, search) {
    //Omregistrerad - "reregistered":true
    const reregFilter = filters.reregistered ? new Set(filters.reregistered.map(r => r.reregistered)) : null;
    //Förstagångsregistrerad - "reregistered":false
    const firstTimeFilter = filters.reregistered ? new Set(filters.reregistered.map(r => ! r.reregistered)) : null;
    const roleFilter = filters.roles ? new Set(filters.roles.map(r => r.role)) : null;
    const enrollmentStateFilter = filters.enrollmentStates ? new Set(filters.enrollmentStates.map(s => s.enrollmentState)) : null;
    const sectionFilter = filters.sections ? new Set(filters.sections.map(s => s.id + '')) : null;
    const programFilter = filters.programs ? new Set(filters.programs.map(s => s.id + '')) : null;
    const groupFilter = filters.groups ? new Set(filters.groups.map(g => g.id + '')) : null;
    const userFilter = groupFilter ? new Set(Object.keys(groupMembers).filter(gId => groupFilter.has(gId))
        .flatMap(gId => groupMembers[gId])) : null;

    const validReReg = enrollment => !reregFilter || reregFilter.has(enrollment.reregistered);
    const validFirstTimeReg = enrollment => ! firstTimeFilter || firstTimeFilter.has(! enrollment.reregistered);

    const validRoles = enrollment => !roleFilter || roleFilter.has(enrollment.role);

    const validEnrollmentStates = enrollment => !enrollmentStateFilter || enrollmentStateFilter.has(enrollment.enrollment_state);
    const validSections = enrollment => !sectionFilter || sectionFilter.has(enrollment.course_section_id + '');
    const validPrograms = enrollment => !programFilter || programFilter.has(enrollment.program_id + '');

    const validUsers = enrollment => !userFilter || userFilter.has(enrollment.user_id);
    const searchUserName = enrollment => enrollment.user_sortable_name.toLowerCase().includes(search.toLowerCase());

    return enrollments.filter(e => validRoles(e) && validEnrollmentStates(e) && validSections(e) && validPrograms(e) &&
        validUsers(e) && searchUserName(e) && validReReg(e) && validFirstTimeReg(e));
}

function mergeUserEnrollments(enrollments) {
    let userEnrollments = {}, ue;
    enrollments.forEach(e => {
        if (!userEnrollments[e.user_id]) {
            let ue = userEnrollments[e.user_id] = Object.assign({}, e, {
                course_section_id: [e.course_section_id],
                role: [e.role],
                program_id: [e.program_id],
            });
        } else {
            let ue = userEnrollments[e.user_id];
            ue.course_section_id = [...ue.course_section_id, e.course_section_id];
            ue.role = [...ue.role, e.role];
        }
    });
    return Object.values(userEnrollments);
}

function enrollmentComparator(i18n, sort, programs, sections, filterGroups, groups, memberGroups) {
    const s = sort.prop === 'user_name' ? Object.assign({}, sort, {prop: 'user_sortable_name'}) : sort;
    const val = e => enrollmentValue(i18n, s, e, programs, sections, filterGroups, groups, memberGroups);
    const asc = sort.asc ? 1 : -1;
    return (e1, e2) => {
        let v1 = val(e1) ?? '';
        let v2 = val(e2) ?? '';
        if (!v1) return 1 * asc; // Sort null & empty strings last
        if (!v2) return -1 * asc; // Sort null & empty strings last
        if (typeof v1 === 'string' && typeof v2 === 'string' && (v1+v2).match(/^\d+$/)) {
            return v1.localeCompare(v2, undefined, {numeric: true}) * asc;
        }
        if (v1 < v2) return -1 * asc;
        if (v1 > v2) return 1 * asc;
        return 0;
    }
}

function enrollmentValue(i18n, header, enrollment, programs, sections, filterGroups, groups, memberGroups) {
    if (header.type === 'enrollment') {
        if (header.prop === 'enrollment_state') {
            return enrollment[header.prop] === 'completed' ? 'X' : '';
        } else if (header.prop === 'reregistered') {
            return enrollment[header.prop] === true? 'X' : '';
        } else {
            return enrollment[header.prop];
        }
    } else if (header.type === 'section') {
        return (enrollment[header.prop] || [])
            .flatMap(sId => sections.filter(s => s.id + '' === sId + ''))
            .map(s => s.name);
    } else if (header.type === 'program') {
            return (enrollment[header.prop]||[])
                .flatMap(sId => programs.filter(s => s.id + '' === sId + ''))
                .map(s => s.name);
    } else if (header.type === 'role') {
        return enrollment[header.prop].map(r => i18n(`table.value.role.${r}`));
    } else if (header.type === 'group') {
        let gs = (filterGroups && filterGroups.length > 0) ? filterGroups : groups||[]
        let mgs = (memberGroups[enrollment.user_id]||[]).join(',');
        return (gs||[])
            .filter(g => mgs.includes(g.id))
            .map(g => g.name)
            .join(', ');
    } else if (header.type === 'checkbox') {
        return {enrollment, type: 'select'};
    } else {
        return '';
    }
}
